// ai-conversation.js
// Import the RealtimeClient
import { RealtimeClient } from "@openai/realtime-api-beta";
import { WavRecorder } from "./lib/wavtools/lib/wav_recorder.js";
import { WavStreamPlayer } from "./lib/wavtools/lib/wav_stream_player.js";

// todo(vincex): change this during deployment
// const LOCAL_RELAY_SERVER_URL = "http://localhost:8081";
const LOCAL_RELAY_SERVER_URL = "https://superpodcast-wvg5.onrender.com";

document.addEventListener("DOMContentLoaded", () => {
  const aiConversationBtn = document.getElementById("ai-conversation-btn");
  const chatBox = document.getElementById("chat-box");
  const closePlayerBtn = document.getElementById("close-player");
  let isRecording = false;
  let client = null;
  let wavRecorder = null;
  let wavStreamPlayer = null;

  /**
   * Initializes or reinitializes the RealtimeClient
   * @async
   */
  const initializeClient = async () => {
    console.log("Initializing RealtimeClient");
    // If client exists, disconnect it first
    if (client) {
      await client.disconnect();
    }

    client = new RealtimeClient({
      url: LOCAL_RELAY_SERVER_URL,
    });

    const connectWithRetry = async (retries = 3, delay = 1000) => {
      try {
        await client.connect();
        console.log("Connected to RealtimeClient");
      } catch (error) {
        console.error("Failed to connect to RealtimeClient:", error);
        if (retries > 0) {
          console.log(`Retrying connection in ${delay}ms...`);
          await new Promise((resolve) => setTimeout(resolve, delay));
          return connectWithRetry(retries - 1, delay * 2);
        } else {
          throw error; // Propagate the error after all retries are exhausted
        }
      }
    };

    await connectWithRetry();

    // Set up event handling
    client.on("conversation.updated", (event) => {
      const { item, delta } = event;
      handleServerMessage(item, delta);
    });
  };

  /**
   * Fetches the conversation context for a given podcast
   * @param {string} podcastId - The ID of the podcast
   * @returns {Promise<Object>} - The conversation context
   */
  async function fetchConversationContext(podcastId) {
    try {
      const response = await fetch(
        `/conversation/real-time/context?podcast_id=${podcastId}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch conversation context");
      }
      return await response.json();
    } catch (error) {
      console.error("Error fetching conversation context:", error);
      return { conversation_history: [], transcript: "" };
    }
  }

  /**
   * Initializes the AI conversation with context and set up VAD
   * @param {string} podcastId - The ID of the podcast
   */
  async function initializeAIConversation(podcastId) {
    try {
      const context = await fetchConversationContext(podcastId);

      // Only initialize the session, don't send an explicit message
      client.updateSession({
        instructions: constructAIPrompt("", context),
        voice: "alloy",
        turn_detection: { type: "server_vad" },
        input_audio_transcription: { model: "whisper-1" },
      });

      console.log("AI conversation initialized with context");
    } catch (error) {
      console.error("Error initializing AI conversation:", error);
    }
  }

  /**
   * Constructs a prompt for the AI assistant based on the user's message and context.
   * @param {string} userMessage - The message from the user.
   * @param {object} context - The context containing conversation history and transcript.
   * @returns {string} - The constructed prompt.
   */

  function constructAIPrompt(userMessage, context) {
    const { conversation_history, transcript } = context;

    const systemInstruction = `
    ## Identity & Context
    You are in the middle of a live podcasts as a host. Someone from the audience interrupt to join the conversation, thank him for joining the conversation and help him.
    ## Style Guardrails
    - Be Concise: Respond succinctly, addressing one topic at most
    - Embrace Variety: Use diverse language and rephrasing to enhance clarity
    - Be Conversational: Use everyday language, like talking to a friend
    - Be Proactive: Lead the conversation, making relevant questions or exploring interesting tangents
    - Keep Focus: Ask only one question at a time
    - Seek Clarity: If answers are unclear or incomplete, follow up thoughtfully

    ## Podcast Transcript (never say "Sophia and Lily", say "WE")
    ${transcript}

    ## Response Guidelines
    - Adapt and Guess: Work with user input even if it contains errors, try to guess what he meant or ask if its nonsense
    - Stay in Character: Guide conversations back to relevant topics naturally
    - Maintain Flow: Keep responses direct and role-appropriate
    - Use Context: Draw from both transcript content and general knowledge
    - Match Tone: Maintain the podcast's style in your responses`;

    let conversationContext = conversation_history.length > 0
        ? `Previous conversation context:\n${conversation_history.map((msg) => `${msg.sender}: ${msg.content}`).join("\n")}`
        : "";

    const userQuery = userMessage ? `The listener asks: "${userMessage}"` : "";

    const prompt = `${systemInstruction}

${conversationContext}

${userQuery}

Your response:`;

    console.log("AI prompt:", prompt);
    return prompt;
  }

  function getPodcastIdFromPage() {
    return window.currentPodcastId;
  }

  const startConversation = async () => {
    try {
      const podcastId = getPodcastIdFromPage();

      document.dispatchEvent(new CustomEvent("stopAudio"));

      // Always initialize a new client when starting a conversation
      await initializeClient();

      console.log("Podcast ID within startConversation:", podcastId);
      await initializeAIConversation(podcastId);
      // Set up interruption handling when user interrupts the conversation
      setupInterruptionHandling();

      wavRecorder = new WavRecorder({ sampleRate: 24000 });
      wavStreamPlayer = new WavStreamPlayer({ sampleRate: 24000 });
      await wavStreamPlayer.connect();

      // Start recording audio and sending to the API
      await wavRecorder.begin();
      await wavRecorder.record((data) => {
        client.appendInputAudio(data.mono);
      });

      isRecording = true;
      aiConversationBtn.textContent = "Disconnect";
    } catch (err) {
      console.error("Error during conversation setup:", err);
      alert("Error starting conversation. Please try again.");
      // Reset the button state
      isRecording = false;
      aiConversationBtn.textContent = "Join";
    }
  };

  const stopConversation = async () => {
    try {
      if (client) {
        // TODO(vincex): better handle this to allow the AI to respond to the final message
        // // Send a final message to the AI
        // await client.sendUserMessageContent([
        //   {
        //     type: 'input_text',
        //     text: "Thank you for answering my question. That's all I needed to know for now. [NARRATOR] The AI should now offer to answer more questions if needed.",
        //   },
        // ]);

        // // Wait for a short period to allow the AI to process the final message and respond
        // await new Promise(resolve => setTimeout(resolve, 3000));

        // Now disconnect the client
        await client.disconnect();
      }

      if (wavRecorder) {
        await wavRecorder.end();
      }
      if (wavStreamPlayer) {
        await wavStreamPlayer.interrupt();
      }
    } catch (err) {
      console.error("Error during conversation cleanup:", err);
    } finally {
      isRecording = false;
      aiConversationBtn.textContent = "Join";
      client = null; // Reset the client
    }
  };

  aiConversationBtn.addEventListener("click", async () => {
    if (!isRecording) {
      console.log("Starting conversation");
      await startConversation();
    } else {
      aiConversationBtn.textContent = "Ending Conversation...";
      aiConversationBtn.disabled = true;
      await stopConversation();
      aiConversationBtn.textContent = "Join";
      aiConversationBtn.disabled = false;
    }
  });

  closePlayerBtn.addEventListener("click", async () => {
    if (isRecording) {
      await stopConversation();
    }
  });

  /**
   * Handles messages received from the server and plays audio with WavStreamPlayer
   * @param {Object} item - The conversation item
   * @param {Object} delta - The delta updates
   */
  function handleServerMessage(item, delta) {
    if (delta?.audio) {
      wavStreamPlayer.add16BitPCM(delta.audio, item.id);
    }
    if (item.status === "completed" && item.formatted.audio?.length) {
      WavRecorder.decode(item.formatted.audio, 24000, 24000).then((wavFile) => {
        item.formatted.file = wavFile;
        // You might want to do something with the completed audio file here
      });
    }
    // Display text messages
    if (item.content) {
      item.content.forEach((contentPart) => {
        if (contentPart.type === "text") {
          console.log("Displaying text:", contentPart.text);
          displayText(contentPart.text);
        }
      });
    }
  }

  /**
   * Displays text in the chat box
   * @param {string} text - The text to display
   */
  function displayText(text) {
    const message = document.createElement("div");
    message.textContent = text;
    chatBox.appendChild(message);
    chatBox.scrollTop = chatBox.scrollHeight;
  }

  /**
   * Sets up interruption handling for the conversation
   */
  function setupInterruptionHandling() {
    client.on("conversation.interrupted", async () => {
      const trackSampleOffset = await wavStreamPlayer.interrupt();
      if (trackSampleOffset?.trackId) {
        const { trackId, offset } = trackSampleOffset;
        await client.cancelResponse(trackId, offset);
      }
    });
  }

}); // End of DOMContentLoaded
