import { StreamProcessorSrc } from "./worklets/stream_processor.js";
import { AudioAnalysis } from "./analysis/audio_analysis.js";

/**
 * Plays audio streams received in raw PCM16 chunks from the browser
 * and records the played audio.
 * @class
 */
export class WavStreamPlayer {
  /**
   * Creates a new WavStreamPlayer instance
   * @param {{sampleRate?: number}} options
   * @returns {WavStreamPlayer}
   */
  constructor({ sampleRate = 44100 } = {}) {
    this.scriptSrc = StreamProcessorSrc;
    this.sampleRate = sampleRate;
    this.context = null;
    this.stream = null;
    this.analyser = null;
    this.trackSampleOffsets = {};
    this.interruptedTrackIds = {};

    // Recording-related properties
    this.destinationNode = null;
    this.mediaRecorder = null;
    this.recordedChunks = [];
    this.isRecording = false;
    this.currentTrackId = null; // To track which AI response is being recorded

    // Add pause-related properties
    this.isPaused = false;
  }

  /**
   * Connects the audio context, sets up nodes for playback and recording,
   * and enables output to speakers.
   * @param {AudioContext} [existingContext] Optional existing AudioContext to use
   * @returns {Promise<true>}
   */
  async connect(existingContext) {
    this.context =
      existingContext || new AudioContext({ sampleRate: this.sampleRate });
    if (this.context.state === "suspended") {
      await this.context.resume();
    }
    try {
      await this.context.audioWorklet.addModule(this.scriptSrc);
    } catch (e) {
      console.error(e);
      throw new Error(`Could not add audioWorklet module: ${this.scriptSrc}`);
    }

    // Initialize the analyser node
    this.analyser = this.context.createAnalyser();
    this.analyser.fftSize = 8192;
    this.analyser.smoothingTimeConstant = 0.1;

    // Set up MediaStreamDestination for recording
    this.destinationNode = this.context.createMediaStreamDestination();

    // Connections will be made in _start()
    return true;
  }

  /**
   * Starts or resumes recording the audio being played.
   */
  startRecording() {
    if (this.isRecording) {
      if (this.isPaused) {
        this.resumeRecording();
      }
      return;
    }

    if (!this.destinationNode) {
      console.error("AudioContext not connected.");
      return;
    }

    // Create MediaRecorder only if it doesn't exist
    if (!this.mediaRecorder) {
      this.mediaRecorder = new MediaRecorder(this.destinationNode.stream, {
        mimeType: "audio/webm;codecs=opus",
        bitsPerSecond: 128000,
      });

      this.mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          this.recordedChunks.push(event.data);
          console.log(
            "Recording segment added, total chunks:",
            this.recordedChunks.length,
            "Last chunk size:",
            event.data.size
          );
        }
      };

      this.mediaRecorder.onerror = (error) => {
        console.error("MediaRecorder error:", error);
      };
    }

    // Start recording if not already recording
    if (this.mediaRecorder.state === "inactive") {
      this.mediaRecorder.start(100);
      this.isRecording = true;
      this.isPaused = false;
      console.log("Recording started/resumed");
    }
  }

  /**
   * Pauses the current recording
   */
  pauseRecording() {
    if (!this.isRecording || !this.mediaRecorder) {
      return;
    }

    console.log("Pausing recording...");
    if (this.mediaRecorder.state === "recording") {
      this.mediaRecorder.pause();
      console.log("Recording paused");
    }
  }

  /**
   * Resumes the paused recording
   */
  resumeRecording() {
    if (!this.isRecording || !this.mediaRecorder) {
      return;
    }

    console.log("Resuming recording...");
    if (this.mediaRecorder.state === "paused") {
      this.mediaRecorder.resume();
      console.log("Recording resumed");
    }
  }

  /**
   * Saves the recording and performs cleanup
   */
  saveRecording() {
    if (this.recordedChunks.length === 0) {
      console.log("No audio recorded. Chunks array is empty.");
      return;
    }

    console.log("Saving recording with", this.recordedChunks.length, "chunks");

    try {
      const blob = new Blob(this.recordedChunks, {
        type: this.mediaRecorder?.mimeType || "audio/webm;codecs=opus",
      });

      console.log("Created blob of size:", blob.size);

      if (blob.size === 0) {
        console.error("Generated blob is empty");
        return;
      }

      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = `conversation_${Date.now()}.webm`;
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(url);
      document.body.removeChild(a);
      console.log("Recording saved successfully");

      // Clean up after saving
      this.cleanup();
    } catch (error) {
      console.error("Error saving recording:", error);
    }
  }

  /**
   * Adds 16BitPCM data to the currently playing audio stream
   * and manages recording continuity.
   * @param {ArrayBuffer|Int16Array} arrayBuffer
   * @param {string} [trackId]
   * @returns {Int16Array}
   */
  add16BitPCM(arrayBuffer, trackId = "default") {
    if (typeof trackId !== "string") {
      throw new Error(`trackId must be a string`);
    } else if (this.interruptedTrackIds[trackId]) {
      return;
    }

    if (!this.stream) {
      this._start();
    }

    let buffer;
    if (arrayBuffer instanceof Int16Array) {
      buffer = arrayBuffer;
    } else if (arrayBuffer instanceof ArrayBuffer) {
      buffer = new Int16Array(arrayBuffer);
    } else {
      throw new Error(`argument must be Int16Array or ArrayBuffer`);
    }

    this.stream.port.postMessage({ event: "write", buffer, trackId });
    return buffer;
  }

  /**
   * Gets the offset (sample count) of the currently playing stream
   * @param {boolean} [interrupt]
   * @returns {{trackId: string|null, offset: number, currentTime: number}}
   */
  async getTrackSampleOffset(interrupt = false) {
    if (!this.stream) {
      return null;
    }
    const requestId = crypto.randomUUID();
    this.stream.port.postMessage({
      event: interrupt ? "interrupt" : "offset",
      requestId,
    });
    let trackSampleOffset;
    while (!trackSampleOffset) {
      trackSampleOffset = this.trackSampleOffsets[requestId];
      await new Promise((r) => setTimeout(() => r(), 1));
    }
    const { trackId } = trackSampleOffset;
    if (interrupt && trackId) {
      this.interruptedTrackIds[trackId] = true;
      // // Stop recording upon interruption
      // this.stopRecording();
    }
    return trackSampleOffset;
  }

  /**
   * Strips the current stream and returns the sample offset of the audio
   * @param {boolean} [interrupt]
   * @returns {{trackId: string|null, offset: number, currentTime: number}}
   */
  async interrupt() {
    return await this.getTrackSampleOffset(true);
  }

  /**
   * Gets the MediaStreamDestination node used for recording
   * @returns {MediaStreamAudioDestinationNode}
   */
  getDestinationNode() {
    if (!this.destinationNode) {
      throw new Error("AudioContext not connected.");
    }
    return this.destinationNode;
  }

  /**
   * Initiates the audio streaming process.
   * @private
   * @returns {Promise<true>}
   */
  async _start() {
    const streamNode = new AudioWorkletNode(this.context, "stream_processor");

    // Connect streamNode to the analyser
    streamNode.connect(this.analyser);

    // Connect analyser to destination node first, then to speakers
    this.analyser.connect(this.destinationNode);
    this.analyser.connect(this.context.destination);

    streamNode.port.onmessage = (e) => {
      const { event } = e.data;
      if (event === "stop") {
        streamNode.disconnect();
        this.stream = null;
      } else if (event === "offset") {
        const { requestId, trackId, offset } = e.data;
        const currentTime = offset / this.sampleRate;
        this.trackSampleOffsets[requestId] = { trackId, offset, currentTime };
      }
    };

    this.stream = streamNode;
    return true;
  }

  /**
   * Gets the current frequency domain data from the playing track
   * @param {"frequency"|"music"|"voice"} [analysisType]
   * @param {number} [minDecibels] default -100
   * @param {number} [maxDecibels] default -30
   * @returns {import('./analysis/audio_analysis.js').AudioAnalysisOutputType}
   */
  getFrequencies(
    analysisType = "frequency",
    minDecibels = -100,
    maxDecibels = -30
  ) {
    if (!this.analyser) {
      throw new Error("Not connected, please call .connect() first");
    }
    return AudioAnalysis.getFrequencies(
      this.analyser,
      this.sampleRate,
      null,
      analysisType,
      minDecibels,
      maxDecibels
    );
  }

  /**
   * Cleans up and stops all recording and playback
   */
  cleanup() {
    this.interrupt();
    // Stop MediaRecorder if active
    if (this.mediaRecorder && this.mediaRecorder.state !== "inactive") {
      this.mediaRecorder.stop();
    }

    // Disconnect audio nodes
    if (this.analyser) {
      this.analyser.disconnect();
    }
    if (this.stream) {
      this.stream.disconnect();
      this.stream = null;
    }

    // Clear recording state
    this.mediaRecorder = null;
    this.recordedChunks = [];
    this.isRecording = false;
    this.isPaused = false;

    // Clear track state
    this.trackSampleOffsets = {};
    this.interruptedTrackIds = {};

    console.log("WavStreamPlayer cleaned up");
  }
}

globalThis.WavStreamPlayer = WavStreamPlayer;
