document.addEventListener("DOMContentLoaded", function () {
  console.log("DOM fully loaded");
  const playerBar = document.getElementById("player-bar");
  console.log("Player bar element:", playerBar);
  // get all the elements from html sent by the BE
  const playPauseButton = document.getElementById("play-pause-button");
  const waveform = document.getElementById("waveform");
  const volumeControl = document.getElementById("volume-control");
  const speedControl = document.getElementById("playback-speed");
  const currentPodcastTitle = document.getElementById("current-podcast-title");
  const currentPodcastImage = document.getElementById("current-podcast-image");

  // Declare a global variable to store the current podcast ID
  window.currentPodcastId = null;

  let wavesurfer = WaveSurfer.create({
    container: waveform,
    waveColor: "pink",
    progressColor: "purple",
    responsive: true,
    height: 10,
  });

  let currentAudio = null;
  let currentPlayingButton = null;
  let playStartTime;
  let playRecorded = false;
  let isAudioPaused = false;
  let playMilestones = new Set();
  const LONG_MILESTONES = [10, 25, 50, 75, 95];
  const SHORT_MILESTONES = [25, 50, 75, 95];
  const SHORT_PODCAST_THRESHOLD = 240; // 2 minutes in seconds
  let actualPlayDuration = 0;
  let lastProcessTime = Date.now();

  wavesurfer.on("ready", function () {
    wavesurfer.play();
    playStartTime = Date.now();
    playRecorded = true;

    // Log the duration and which milestone set we're using
    console.log(`Podcast duration: ${wavesurfer.getDuration()} seconds`);
    console.log(
      `Using ${
        wavesurfer.getDuration() <= SHORT_PODCAST_THRESHOLD ? "short" : "long"
      } milestone set`
    );
  });

  document.body.addEventListener("click", function (event) {
    if (event.target.closest(".play-pause-btn")) {
      console.log("Play button clicked");
      const button = event.target.closest(".play-pause-btn");
      const audioSrc = button.dataset.audioSrc;
      const podcastId = button.dataset.podcastId;
      const podcastTitle = button.dataset.podcastTitle;
      const podcastImage = button.dataset.podcastImage;

      // Set the global currentPodcastId
      window.currentPodcastId = podcastId;

      console.log("Audio source:", audioSrc);
      console.log("Podcast ID:", podcastId);
      console.log("Podcast title:", podcastTitle);
      console.log("Podcast image:", podcastImage);

      if (currentAudio !== audioSrc) {
        // New podcast selected
        wavesurfer.load(audioSrc);
        currentAudio = audioSrc;
        playRecorded = false; // Reset flag for new podcast
      } else if (!wavesurfer.isPlaying() && !playRecorded) {
        // Same podcast, not playing, and play hasn't been recorded yet
        wavesurfer.play();
        playStartTime = Date.now();
        playRecorded = true;
      } else {
        // Toggle play/pause for current podcast
        wavesurfer.playPause();
        if (wavesurfer.isPlaying()) {
          playStartTime = Date.now();
        } else if (playStartTime) {
          // Don't record play here, just update playStartTime
          playStartTime = null;
        }
      }

      // Update button state
      updateButtonState(button);

      // Update player bar
      playerBar.classList.remove("hidden");
      playerBar.classList.add("show");
      console.log("Player bar classes after update:", playerBar.className);
      console.log("Player bar visibility:", playerBar.style.display);
      console.log(
        "Player bar computed style:",
        window.getComputedStyle(playerBar).display
      );
      currentPodcastTitle.textContent = podcastTitle;
      currentPodcastImage.src = podcastImage;
      currentPodcastImage.alt = `${podcastTitle} cover`;

      if (currentPlayingButton && currentPlayingButton !== button) {
        updateButtonState(currentPlayingButton);
      }
      currentPlayingButton = button;
    }
  });

  // Update the stopAudio event listener
  document.addEventListener("stopAudio", function () {
    if (wavesurfer.isPlaying()) {
      wavesurfer.pause();
      isAudioPaused = true;
      updatePlayPauseButton();
      if (currentPlayingButton) {
        updateButtonState(currentPlayingButton);
      }
      if (playStartTime) {
        playStartTime = null;
      }
    }
  });

  // Add a new resumeAudio event listener
  document.addEventListener("resumeAudio", function () {
    if (isAudioPaused && currentAudio) {
      wavesurfer.play();
      isAudioPaused = false;
      updatePlayPauseButton();
      if (currentPlayingButton) {
        updateButtonState(currentPlayingButton);
      }
      playStartTime = Date.now();
    }
  });

  function updateButtonState(button) {
    const icon = button.querySelector("i");
    if (wavesurfer.isPlaying() && currentAudio === button.dataset.audioSrc) {
      icon.classList.remove("fa-play");
      icon.classList.add("fa-pause");
      button.classList.add("playing");
    } else {
      icon.classList.remove("fa-pause");
      icon.classList.add("fa-play");
      button.classList.remove("playing");
    }
  }

  function updatePlayPauseButton() {
    if (wavesurfer.isPlaying()) {
      playPauseButton.innerHTML = '<i class="fas fa-pause"></i>';
    } else {
      playPauseButton.innerHTML = '<i class="fas fa-play"></i>';
    }
  }

  playPauseButton.addEventListener("click", function () {
    wavesurfer.playPause();
    updatePlayPauseButton();
    if (currentPlayingButton) {
      updateButtonState(currentPlayingButton);
    }
    if (wavesurfer.isPlaying()) {
      playStartTime = Date.now();
      isAudioPaused = false;
    } else {
      playStartTime = null;
      isAudioPaused = true;
    }
  });

  wavesurfer.on("play", function () {
    updatePlayPauseButton();
    if (currentPlayingButton) {
      updateButtonState(currentPlayingButton);
    }
    isAudioPaused = false;
    lastProcessTime = Date.now();
  });

  wavesurfer.on("pause", function () {
    updatePlayPauseButton();
    if (currentPlayingButton) {
      updateButtonState(currentPlayingButton);
    }
    isAudioPaused = true;
    updateActualPlayDuration();
  });

  volumeControl.addEventListener("input", function () {
    wavesurfer.setVolume(this.value);
  });

  speedControl.addEventListener("change", function () {
    wavesurfer.setPlaybackRate(parseFloat(this.value));
  });

  // Function to format time in mm:ss
  function formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes
      .toString()
      .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
  }

  // Update the time display as the audio plays
  wavesurfer.on("audioprocess", function () {
    const currentTime = wavesurfer.getCurrentTime();
    const duration = wavesurfer.getDuration();
    updateTimeDisplay(currentTime, duration);

    // Update actual play duration
    updateActualPlayDuration();

    // Only handle milestone if we're actually playing (not dragging)
    if (!isAudioPaused) {
      handleMilestone(currentTime, duration);
    }
  });

  function updateTimeDisplay(currentTime, duration) {
    const formattedCurrent = formatTime(currentTime);
    const formattedDuration = formatTime(duration);
    document.getElementById(
      "time-display"
    ).textContent = `${formattedCurrent} / ${formattedDuration}`;
  }

  // Add this new event listener
  document.addEventListener("playPodcast", function (event) {
    const { audioSrc, podcastId, podcastTitle, podcastImage } = event.detail;

    // Load and play the new audio
    wavesurfer.load(audioSrc);
    wavesurfer.on("ready", function () {
      wavesurfer.play();
      playStartTime = Date.now();
      recordPlay(podcastId, 0); // Record play immediately for new podcast
      playRecorded = true;
    });

    // Update player bar
    playerBar.classList.remove("hidden");
    playerBar.classList.add("show");
    currentPodcastTitle.textContent = podcastTitle;
    currentPodcastImage.src = podcastImage;
    currentPodcastImage.alt = `${podcastTitle} cover`;

    // Update current audio and reset flags
    currentAudio = audioSrc;
    playRecorded = false;

    // Update play button state
    updateButtonState(playPauseButton);
  });

  let debounceTimeout = null;
  function recordPlayWithDebounce(podcastId, duration) {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    debounceTimeout = setTimeout(() => {
      recordPlay(podcastId, duration);
      debounceTimeout = null;
    }, 500); // 500ms debounce interval
  }

  // Add this function to handle milestone tracking
  function handleMilestone(currentTime, duration) {
    const progressPercent = Math.floor((currentTime / duration) * 100);
    const milestones =
      duration <= SHORT_PODCAST_THRESHOLD ? SHORT_MILESTONES : LONG_MILESTONES;

    milestones.forEach((milestone) => {
      if (progressPercent >= milestone && !playMilestones.has(milestone)) {
        playMilestones.add(milestone);
        console.log(`Reached ${milestone}% milestone`);
        // Record play with actual listened duration
        if (window.currentPodcastId) {
          recordPlayWithDebounce(window.currentPodcastId, actualPlayDuration);
        }
      }
    });
  }

  // Add seeking handlers
  wavesurfer.on("seek", function () {
    // Reset the process timer when seeking
    lastProcessTime = Date.now();
  });

  // Add finish handler explicitly
  wavesurfer.on("finish", function () {
    const duration = wavesurfer.getDuration();
    const finalMilestone = duration <= SHORT_PODCAST_THRESHOLD ? 80 : 100;

    // Ensure final milestone is recorded
    if (!playMilestones.has(finalMilestone) && window.currentPodcastId) {
      playMilestones.add(finalMilestone);
      recordPlay(window.currentPodcastId, actualPlayDuration);
    }
  });

  // Add this function to track actual play duration
  function updateActualPlayDuration() {
    const now = Date.now();
    if (!isAudioPaused) {
      actualPlayDuration += (now - lastProcessTime) / 1000;
    }
    lastProcessTime = now;
  }
});
