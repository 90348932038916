// Function to handle liking a podcast
function likePodcast(podcastId, button) {
  fetch(`/like_podcast/${podcastId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  })
  .then(response => {
    if (!response.ok) {
      if (response.status === 401) {
        // Redirect to login page or show login prompt
        window.location.href = '/auth/login';
        throw new Error('Please login to like podcasts');
      }
      return response.json().then(err => { throw err; });
    }
    return response.json();
  })
  .then(data => {
    if (data.success) {
      const likeCount = button.querySelector('span');
      likeCount.textContent = data.likes_count;
      if (data.action === 'liked') {
        // Apply "liked" styles
        button.classList.add('text-blue-500');
        button.classList.remove('text-gray-500');
      } else {
        // Apply "unliked" styles
        button.classList.add('text-gray-500');
        button.classList.remove('text-blue-500');
      }
    }
  })
  .catch(error => {
    console.error('Error:', error);
    // Handle the error (e.g., show an error message to the user)
  });
}
// Add event listeners when the DOM is fully loaded
document.addEventListener('DOMContentLoaded', function() {
  // Add event listeners for like buttons
  const likeButtons = document.querySelectorAll('.like-button');
  likeButtons.forEach(button => {
    button.addEventListener('click', function() {
      const podcastId = this.dataset.podcastId;
      likePodcast(podcastId, this);
    });
  });
});
