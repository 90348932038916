// static/js/rrss.js

document.addEventListener("DOMContentLoaded", function () {
  const shareButtons = document.querySelectorAll(".share-button");
  const shareOverlay = document.getElementById("share-overlay");
  const closePopupButton = document.getElementById("close-share-popup");
  // const downloadAudioButton = document.getElementById('download-audio');
  let currentPodcastId = null;

  shareButtons.forEach((button) => {
    button.addEventListener("click", function () {
      currentPodcastId = this.getAttribute("data-podcast-id");
      shareOverlay.classList.add("active");
      // downloadAudioButton.setAttribute('data-podcast-id', currentPodcastId);
    });
  });

  shareOverlay.addEventListener("click", function (event) {
    // Close only if clicking the overlay itself (not its children)
    if (event.target === shareOverlay) {
      shareOverlay.classList.remove("active");
    }
  });

  closePopupButton.addEventListener("click", function () {
    shareOverlay.classList.remove("active");
  });

  document
    .getElementById("share-twitter")
    .addEventListener("click", function () {
      shareOnTwitter(currentPodcastId);
    });

  document
    .getElementById("share-facebook")
    .addEventListener("click", function () {
      shareOnFacebook(currentPodcastId);
    });

  document
    .getElementById("share-linkedin")
    .addEventListener("click", function () {
      shareOnLinkedIn(currentPodcastId);
    });

  document.getElementById("copy-url").addEventListener("click", function () {
    copyPodcastUrl(currentPodcastId);
  });
});

function shareOnTwitter(podcastId) {
  const url = `${window.location.origin}/podcast/${podcastId}`;
  const text = `Check out this AI-generated INTERACTIVE podcast on SuperPodcast.ai! This is the future of content! Customize your podcast with a prompt, and join the conversation! Available in english & spanish`;
  const shareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
    text
  )}&url=${encodeURIComponent(url)}`;
  window.open(shareUrl, "_blank");
}

function shareOnFacebook(podcastId) {
  const url = `${window.location.origin}/podcast/${podcastId}`;
  const text = `Listen to my new INTERACTIVE podcast generated with SuperPodcast.ai! Its amazing! Interactive content is the future! You can join the podcast conversation!
🎙️ Customize with a prompt: the tone, key points & AI hosts personalities
🤖 Join real-time the podcast with the AI hosts, its like talking with expert friends! They call this type of Podcasts: LiveCasts!
🌍 Discover podcasts
🇪🇸 Create in Spanish too!
Built in SF 🌁! Try it now!
#Superpodcast #AIChat #JoinTheConversation`;
  window.open(
    `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      url
    )}&quote=${encodeURIComponent(text)}`,
    "_blank"
  );
}

function shareOnLinkedIn(podcastId) {
  const url = `${window.location.origin}/podcast/${podcastId}`;
  const title = `Interactive AI Podcasts on Superpodcast.ai`;
  const summary = `Listen to my new podcast generated with superpodcast.ai! Its amazing! Interactive content just arrived! You can join the podcast conversation!
🎙️ Customize with a prompt: the tone, key points & AI hosts personalities
🤖 Join real-time the podcast with the AI hosts, its like talking with expert friends! They call this type of Podcasts: LiveCasts!
🌍 Discover podcasts
🇪🇸 Create in Spanish too!
Built in SF 🌁! Try it now!
#Superpodcast #AIChat #JoinTheConversation`;
  window.open(
    `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
      url
    )}&title=${encodeURIComponent(title)}&summary=${encodeURIComponent(
      summary
    )}`,
    "_blank"
  );
}

function copyPodcastUrl(podcastId) {
  const url = `${window.location.origin}/podcast/${podcastId}`;
  navigator.clipboard
    .writeText(url)
    .then(() => {
      alert("URL copied to clipboard!");
    })
    .catch((err) => {
      console.error("Failed to copy URL: ", err);
    });
}
