import { RealtimeClient } from "@openai/realtime-api-beta";

export class CustomRealtimeClient extends RealtimeClient {
  constructor({
    url,
    apiKey,
    podcastId,
    dangerouslyAllowAPIKeyInBrowser,
    debug,
  } = {}) {
    if (!url) {
      throw new Error("url is required");
    }

    // Ensure we're using ws:// protocol
    const baseUrl = url.startsWith("https")
      ? url.replace(/^https/, "wss")
      : url.replace(/^http/, "ws");

    // Construct URL with ALL query parameters
    const params = new URLSearchParams();
    if (podcastId !== null) {
      // Only append if podcastId is provided
      params.append("podcast_id", podcastId);
    }
    params.append("model", "gpt-4o-realtime-preview-2024-10-01"); // Add model parameter here

    const wsUrl = `${baseUrl}?${params.toString()}`;

    if (debug) {
      console.log("Initializing WebSocket with URL:", wsUrl);
    }

    // Pass the constructed URL to the parent class
    super({
      url: wsUrl,
      apiKey,
      dangerouslyAllowAPIKeyInBrowser,
      debug,
    });

    this.podcastId = podcastId;
    this.debug = debug;
  }

  // Override the connect method to pass an empty model
  async connect() {
    if (this.isConnected()) {
      throw new Error(`Already connected, use .disconnect() first`);
    }
    // Pass empty model to prevent adding ?model= to the URL
    await this.realtime.connect({ model: "" });
    this.updateSession();
    return true;
  }
}
