// ai-conversation.js
// Import the RealtimeClient
import { CustomRealtimeClient } from "./custom-realtime-client.js";
import { WavRecorder } from "./lib/wavtools/lib/wav_recorder.js";
import { WavStreamPlayer } from "./lib/wavtools/lib/wav_stream_player.js";
import {
  shouldUseRetell,
  initializeRetellConversation,
} from "./retell-conversation.js";

// todo(vincex): change this during deployment
// const RELAY_SERVER_URL = "http://localhost:8000/realtime/single-streaming";
const SINGLE_HOST_SERVER_URL = "/realtime/single-streaming";

document.addEventListener("DOMContentLoaded", () => {
  const aiConversationBtn = document.getElementById("ai-conversation-btn");
  const chatBox = document.getElementById("chat-box");
  let isRecording = false;
  let client = null;
  let wavRecorder = null;
  let wavStreamPlayer = null;

  /**
   * Initializes or reinitializes the RealtimeClient
   * @async
   */
  const initializeClient = async () => {
    console.log("Initializing RealtimeClient");
    // If client exists, disconnect it first
    if (client) {
      await client.disconnect();
    }

    try {
      const podcastId = getPodcastIdFromPage();
      if (!podcastId) {
        console.log("No podcast ID found");
      }

      client = new CustomRealtimeClient({
        url: SINGLE_HOST_SERVER_URL,
        podcastId: podcastId,
        debug: false,
      });

      const connectWithRetry = async (retries = 3, delay = 1000) => {
        try {
          console.log(`Attempting to connect to ${SINGLE_HOST_SERVER_URL}`);
          await client.connect();
          console.log("Successfully connected to RealtimeClient");
        } catch (error) {
          console.error("Failed to connect to RealtimeClient:", error);
          if (retries > 0) {
            console.log(
              `Retrying connection in ${delay}ms... (${retries} attempts remaining)`
            );
            await new Promise((resolve) => setTimeout(resolve, delay));
            return connectWithRetry(retries - 1, delay * 2);
          } else {
            throw new Error(
              `Failed to connect after multiple attempts: ${error.message}`
            );
          }
        }
      };

      await connectWithRetry();
      if (window.systemPrompt) {
        console.log("System prompt found, using it");
        // Send the update to the server
        client.realtime.ws.send(
          JSON.stringify({
            type: "session.update_instructions",
            instructions: window.systemPrompt,
          })
        );
      }
    } catch (error) {
      console.error("Error during client initialization:", error);
      throw error;
    }

    // Set up event handling
    client.on("conversation.updated", (event) => {
      const { item, delta } = event;
      handleServerMessage(item, delta);
    });

    // Add connection status monitoring
    client.on("error", (error) => {
      console.error("WebSocket error:", error);
    });

    client.on("close", () => {
      console.log("WebSocket connection closed");
    });

    setupInterruptionHandling();
  };

  function getPodcastIdFromPage() {
    return window.currentPodcastId;
  }

  const startConversation = async () => {
    try {
      const podcastId = getPodcastIdFromPage();

      document.dispatchEvent(new CustomEvent("stopAudio"));

      console.log("Podcast ID within startConversation:", podcastId);

      wavRecorder = new WavRecorder({
        sampleRate: 24000,
        outputToSpeakers: false,
      });
      wavStreamPlayer = new WavStreamPlayer({ sampleRate: 24000 });

      // Start recording and share the context
      const sharedContext = await wavRecorder.begin();
      await wavStreamPlayer.connect(sharedContext);

      // Connect wavRecorder to wavStreamPlayer's destination node
      wavRecorder.connectToDestination(wavStreamPlayer.getDestinationNode());

      await initializeClient();

      await wavRecorder.record((data) => {
        client.appendInputAudio(data.mono);
      });

      isRecording = true;
      aiConversationBtn.textContent = "Disconnect";
    } catch (err) {
      console.error("Error during conversation setup:", err);
      alert("Error starting conversation. Please try again.");
      isRecording = false;
      aiConversationBtn.textContent = "Join";
    }
  };

  const stopConversation = async () => {
    try {
      if (wavStreamPlayer.isRecording) {
        wavStreamPlayer.saveRecording();
      } else {
        wavStreamPlayer.cleanup();
      }
      // Clean up recorder
      if (wavRecorder) {
        await wavRecorder.end();
      }

      // Disconnect client last
      if (client) {
        await client.disconnect();
      }
    } catch (err) {
      console.error("Error during conversation cleanup:", err);
    } finally {
      isRecording = false;
      aiConversationBtn.textContent = "Join";
      // Clear all references
      wavRecorder = null;
      wavStreamPlayer = null;
      client = null;
    }
  };
  // <-------start of core of the logics here---------->
  // Wrapper function to handle both conversation types: Retell vs openai
  const handleConversation = async () => {
    const podcastId = getPodcastIdFromPage();
    const useRetell = await shouldUseRetell(podcastId);

    if (useRetell) {
      // Initialize Retell if not already initialized
      if (!window.retellToggleFn) {
        window.retellToggleFn = await initializeRetellConversation(podcastId);
      }
      // Call the Retell conversation toggle
      document.dispatchEvent(new CustomEvent("stopAudio"));
      await window.retellToggleFn();
    } else {
      // Use existing OpenAI conversation logic
      if (!isRecording) {
        console.log("Starting conversation");
        await startConversation();
      } else {
        aiConversationBtn.textContent = "Ending Conversation...";
        aiConversationBtn.disabled = true;
        await stopConversation();
        aiConversationBtn.textContent = "Join";
        aiConversationBtn.disabled = false;
        document.dispatchEvent(new CustomEvent("resumeAudio"));
      }
    }
  };

  // Attach the wrapper function as the click handler
  aiConversationBtn.addEventListener("click", handleConversation);

  // <-------Core of the logics here---------->

  /**
   * Handles messages received from the server and plays audio with WavStreamPlayer
   * @param {Object} item - The conversation item
   * @param {Object} delta - The delta updates
   */
  function handleServerMessage(item, delta) {
    if (delta?.audio) {
      wavStreamPlayer.add16BitPCM(delta.audio, item.id);
      document.dispatchEvent(
        new CustomEvent("ai-audio", { detail: { audio: delta.audio } })
      );
    }
    if (item.status === "completed" && item.formatted.audio?.length) {
      WavRecorder.decode(item.formatted.audio, 24000, 24000).then((wavFile) => {
        item.formatted.file = wavFile;
        // You might want to do something with the completed audio file here
      });
    }
  }

  /**
   * Sets up interruption handling for the conversation
   */
  function setupInterruptionHandling() {
    client.on("conversation.interrupted", async () => {
      const trackSampleOffset = await wavStreamPlayer.interrupt();
      if (trackSampleOffset?.trackId) {
        const { trackId, offset } = trackSampleOffset;
        await client.cancelResponse(trackId, offset);
        console.log("Interrupted conversation", trackId, offset);
      }
    });
  }

  // Add these event listeners after setting up the client
  document.addEventListener("recording.start", () => {
    if (wavStreamPlayer) {
      wavStreamPlayer.startRecording();
    }
  });

  document.addEventListener("recording.pause", () => {
    if (wavStreamPlayer) {
      wavStreamPlayer.pauseRecording();
    }
  });

  document.addEventListener("recording.resume", () => {
    if (wavStreamPlayer) {
      wavStreamPlayer.resumeRecording();
    }
  });

  document.addEventListener("recording.save", () => {
    if (wavStreamPlayer) {
      wavStreamPlayer.saveRecording();
    }
  });
});
