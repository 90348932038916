// retell-conversation.js

import { RetellWebClient } from "retell-client-js-sdk";

export async function shouldUseRetell(podcastId) {
  try {
    const response = await fetch(
      `/retell/check-podcast-retell-access/${podcastId}`
    );
    if (!response.ok) {
      console.error("Failed to check Retell access");
      return false;
    }
    const data = await response.json();
    return data.has_access;
  } catch (error) {
    console.error("Error checking Retell access:", error);
    return false;
  }
}

export async function initializeRetellConversation(podcastId) {
  const retellWebClient = new RetellWebClient();
  let isCalling = false;

  const aiConversationBtn = document.getElementById("ai-conversation-btn");

  // Initialize the SDK
  retellWebClient.on("call_started", () => {
    console.log("call started");
    aiConversationBtn.textContent = "Disconnect";
  });

  retellWebClient.on("call_ended", () => {
    console.log("call ended");
    isCalling = false;
    aiConversationBtn.textContent = "Join";
  });

  const toggleConversationFn = async () => {
    if (isCalling) {
      retellWebClient.stopCall();
      // Resume audio when stopping the call
      document.dispatchEvent(new CustomEvent("resumeAudio"));
    } else {
      // Stop audio before starting the call
      document.dispatchEvent(new CustomEvent("stopAudio"));
      const registerCallResponse = await registerCall(podcastId);
      if (registerCallResponse.access_token) {
        retellWebClient
          .startCall({
            accessToken: registerCallResponse.access_token,
          })
          .catch(console.error);
        isCalling = true;
        aiConversationBtn.textContent = "Disconnect";
      }
    }
  };

  async function registerCall(podcastId) {
    try {
      const response = await fetch("/retell/create-web-call", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          podcast_id: podcastId,
          metadata: {},
          retell_llm_dynamic_variables: {}
        }),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const data = await response.json();
      return data;
    } catch (err) {
      console.error(err);
      throw new Error(err);
    }
  }

  // Return the toggle function instead of attaching the event listener
  return toggleConversationFn;
}

async function createRetellWebCall(podcastId) {
  try {
    const response = await fetch('/api/retell/create-web-call', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        podcast_id: podcastId,
        // Add any additional metadata or dynamic variables if needed
        metadata: {},
        retell_llm_dynamic_variables: {}
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to create web call');
    }

    const data = await response.json();
    return data.access_token;
  } catch (error) {
    console.error('Error creating web call:', error);
    throw error;
  }
}
