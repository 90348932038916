// static/js/main.js

// Import OpenAI Realtime Client
import { RealtimeClient } from "@openai/realtime-api-beta";

// Make RealtimeClient available globally if needed
window.RealtimeClient = RealtimeClient;

// Import your scripts for base.html
import "./ai-conversation.js";
import "./audio-player.js";
import "./podcast.js";
import "./rrss.js";
